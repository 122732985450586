@use "./styles/vars.module" as vars;
@import "./styles/custom.scss";

@font-face {
  font-family: Suisse Intl;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: Suisse Intl;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Suisse Intl", sans-serif;
  color: vars.$black;
  background: vars.$blue2;
}

.tooltip-inner {
  width: 500px;
  max-width: 95vw;
  text-align: left;
}

.tooltip-inner > p {
  font-size: 1.2em;
}

.bottom-section {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
  & > a {
    color: vars.$blue;
  }
}
