@use "../../../styles/vars.module" as vars;

.dropzone-drop-area {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
  padding: 8vh;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  border: 2px solid vars.$blue;
  color: vars.$blue;
}

.dropzone-icon {
  color: inherit;
  height: 115px;
  width: 115px;
  margin-bottom: 20px;
  @media screen and (max-width: vars.$big) {
    height: calc(115px / vars.$big * 100vw);
    width: calc(115px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    height: calc(60px / vars.$medium * 100vw);
    width: calc(60px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    height: calc(60px / vars.$small * vars.$smallCoef * 100vw);
    width: calc(60px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.dropzone-container {
  margin-top: 10vh;
}

.spinner {
  margin-top: 8vh;
}
