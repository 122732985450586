// COLORS
$black: #263b47;
$blue: #036ab5;
$blue2: #d8e3ec;
$white: #ffffff;
$error_color: #ff3a2c;
$show_burger: 1211px;
$hide_suggestion: 810px;

// BREAKPOINTS
$large: 1920px;
$big: 1920px;
$medium: 1024px;
$small: 600px;

// coefficient is needed since actual layouts
// are bigger than figma layouts
$smallCoef: calc($small / 390px);
