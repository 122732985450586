@use "../../../styles/vars.module" as vars;

.wrapper {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  margin-top: 20px;
  background: vars.$black;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.container {
  color: vars.$white;
  max-width: 1200px;
  width: 100%;
  padding: 5px 0;
  justify-self: center;
  display: flex;
  gap: 20px;
  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: vars.$blue;
    }
  }
  @media screen and (max-width: vars.$big) {
    padding: calc(5px / vars.$big * 100vw) 0;
  }

  @media screen and (max-width: vars.$medium) {
    padding: calc(5px / vars.$medium * 100vw) 10px;
  }

  @media screen and (max-width: vars.$small) {
    font-size: 12px;
    padding: calc(5px / vars.$small * vars.$smallCoef * 100vw) 10px;
  }
}
